let content = {
    spectacle: "Trash Quiz",
    representations: [
        {
            date: "27 août 2021",
            place: "Plage des Eaux-Vives dès 16h",
        },
        {
            date: "11 septembre 2021",
            place: "Plage des Eaux-Vives dès 16h",
        },
    ],
}

export default content
